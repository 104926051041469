import { HTTP } from '@/libs/https.js';
import config from '@/libs/config.js';
import { uploadAction, uploadVideoAction } from "@/libs/settings";

class CommonModel extends HTTP {
    getAreaDropDown(data) {
        return new Promise((resolve, reject) => {
            this.axiosGet({
                url: data.parentCode ? config.API.AREA_DROP_DOWN + `?parentCode=${data.parentCode}` : config.API.AREA_DROP_DOWN,
                data: data,
                header: 'application/json',
                success(data) {
                    resolve({
                        data,
                        status: 0
                    });
                },
                error(error) {
                    resolve({
                        error,
                        status: -1
                    })
                }
            });
        });
    };
    uploadFile(data) {
        return new Promise((resolve, reject) => {
            this.axiosFile({
                url: uploadAction,
                data: data,
                header: 'application/json',
                success(data) {
                    resolve({
                        data,
                        status: 0
                    });
                },
                error(error) {
                    resolve({
                        error,
                        status: -1
                    })
                }
            });
        });
    };
    uploadByPeople(data) {
        return new Promise((resolve, reject) => {
            this.axiosFile({
                url:  config.API.UPLOAD_BY_PEOPLE,
                data: data,
                header: 'application/json',
                success(data) {
                    resolve({
                        data,
                        status: 0
                    });
                },
                error(error) {
                    resolve({
                        error,
                        status: -1
                    })
                }
            });
        });
    };
    uploadVideo(data) {
        return new Promise((resolve, reject) => {
            this.axiosFile({
                url: uploadVideoAction,
                data: data,
                header: 'application/json',
                success(data) {
                    resolve({
                        data,
                        status: 0
                    });
                },
                error(error) {
                    resolve({
                        error,
                        status: -1
                    })
                }
            });
        });
    };
    // 获取部门列表(只是第一层)
    getOrganBySchool(data) {
        return new Promise((resolve, reject) => {
            this.axiosPost({
                url: config.API.GET_ORIGAN_SCHOOL,
                data: data,
                header: 'application/json',
                success(data) {
                    resolve({
                        data,
                        status: 0
                    });
                },
                error(error) {
                    resolve({
                        error,
                        status: -1
                    })
                }
            });
        });
    };
    // 根据父级id获取子级列表
    getChildSchema(data) {
        return new Promise((resolve, reject) => {
            this.axiosPost({
                url: config.API.GET_CHILD_SCHEMA,
                data: data,
                header: 'application/json',
                success(data) {
                    resolve({
                        data,
                        status: 0
                    });
                },
                error(error) {
                    resolve({
                        error,
                        status: -1
                    })
                }
            });
        });
    };
    // 获取学生
    getPeopleList(data) {
        return new Promise((resolve, reject) => {
            this.axiosPost({
                url: config.API.GET_PEOPLE_LIST,
                data: data,
                header: 'application/json',
                success(data) {
                    resolve({
                        data,
                        status: 0
                    });
                },
                error(error) {
                    resolve({
                        error,
                        status: -1
                    })
                }
            });
        });
    };
    // 获取教师下拉列表

   getTeacherList(data) {
        return new Promise((resolve, reject) => {
            this.axiosPost({
                url: config.API.GET_TEACHER_LIST,
                data: data,
                header: 'application/json',
                success(data) {
                    resolve({
                        data,
                        status: 0
                    });
                },
                error(error) {
                    resolve({
                        error,
                        status: -1
                    })
                }
            });
        });
    };

    // 获取学生
    getCommonUserList(data) {
        return new Promise((resolve, reject) => {
            this.axiosPost({
                url: config.API.GET_COMMON_USER_LIST,
                data: data,
                header: 'application/json',
                success(data) {
                    resolve({
                        data,
                        status: 0
                    });
                },
                error(error) {
                    resolve({
                        error,
                        status: -1
                    })
                }
            });
        });
    };

    autoLoginSchoolFromOldOperate(data) {
        return new Promise((resolve, reject) => {
            this.axiosPost({
                url: config.API.AUTO_LOGIN_SCHOOL_FROM_OLD_OPERATE,
                data: data,
                header: 'application/json',
                success(data) {
                    resolve({
                        data,
                        status: 0
                    });
                },
                error(error) {
                    resolve({
                        error,
                        status: -1
                    })
                }
            });
        });
    };

    autoLoginSchoolFromUsbKey(data){
        return new Promise((resolve, reject) => {
            this.axiosPost({
                url: config.API.AUTO_LOGIN_SCHOOL_FROM_USB_KEY,
                data: data,
                header: 'application/json',
                success(data) {
                    resolve({
                        data,
                        status: 0
                    })
                },
                error(error) {
                    resolve({
                        error,
                        status: -1
                    })
                }
            });
        });
    }
    
    getAddressBookList(data) {
        return new Promise((resolve, reject) => {
            this.axiosPost({
                url: config.API.GET_ADDRESS_BOOK_LIST,
                data: data,
                header: 'application/json',
                success(data) {
                    resolve({
                        data,
                        status: 0
                    });
                },
                error(error) {
                    resolve({
                        error,
                        status: -1
                    })
                }
            });
        });
    };
    getAddressBookList1(data) {
        return new Promise((resolve, reject) => {
            this.axiosPost({
                url: config.API.GET_MYCOMMON_USER_LIST,
                data: data,
                header: 'application/json',
                success(data) {
                    resolve({
                        data,
                        status: 0
                    });
                },
                error(error) {
                    resolve({
                        error,
                        status: -1
                    })
                }
            });
        });
    };
    getDictList(data) {
        return new Promise((resolve, reject) => {
            this.axiosGet({
                url: config.API.GET_DICT_LIST + `?dictKey=${data}`,
                data: data,
                header: 'application/json',
                success(data) {
                    resolve({
                        data,
                        status: 0
                    });
                },
                error(error) {
                    resolve({
                        error,
                        status: -1
                    })
                }
            });
        });
    };
}

export { CommonModel };
